import { Directive, ElementRef, HostListener, inject, Input } from "@angular/core";

@Directive({
  selector: "[appImgFallback]",
  standalone: true,
})
export class ImgFallbackDirective {
  @Input() appImgFallback = "";
  @Input() fallbackStyle = "";

  private element = inject(ElementRef);

  @HostListener("error")
  displayFallbackImg() {
    const url = this.appImgFallback ? this.appImgFallback : "/assets/logo/empty-company.svg";
    this.element.nativeElement.src = url;
    if (this.fallbackStyle)
      this.fallbackStyle
        .split(" ")
        .map(split => this.element.nativeElement?.classList?.toggle(split));
  }
}
