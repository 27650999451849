import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

import { MediaUrlPipe } from "@pipes/media-url.pipe";
import { AvatarModule } from "primeng/avatar";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: "app-avatar-meta",
  standalone: true,
  imports: [CommonModule, AvatarModule, MediaUrlPipe, TooltipModule],
  templateUrl: "./avatar-meta.component.html",
  styleUrls: ["./avatar-meta.component.scss"],
})
export class AvatarMetaComponent {
  @Input() type: "company" | "forum" | "user" = "user";
  @Input() title = "";
  @Input() description = "";
  @Input() extra = "";
  @Input() avatar = "";
  @Input() size: "medium" | "normal" | "large" | "xlarge" = "normal";
  @Input() imgSize: "normal" | "large" | "xlarge" | undefined;
  @Input() labelDirection: "row" | "col" = "col";
  @Input() labelClass = "";
  @Input() titleClass = "";
  @Input() descriptionClass = "";
  @Input() containerClass = "";
  @Input() labelContainerClass = "";
  @Input() extraClass = "";
  @Input() customDescription = false;
  @Input() tooltip = false;

  error(event: Event) {
    if (event.target)
      if (this.type === "company")
        // @ts-ignore
        event.target.src = "/assets/logo/empty-company.svg";
      // @ts-ignore
      else if (this.type === "user") event.target.src = "/assets/logo/empty-user.svg";
      // @ts-ignore
      else event.target.src = "/assets/logo/empty-community.svg";
  }
}
