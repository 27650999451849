import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";

import { LocalStorageService } from "@services/local-storage.service";
import { Observable } from "rxjs";

export const TokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const localService = inject(LocalStorageService);
  const authRequest = req.clone({
    headers: req.headers.set("Authorization", `Bearer ${localService.getData("access")}`),
  });
  return next(authRequest);
};
