import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

import { Observable, of, tap } from "rxjs";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache: Map<string, HttpResponse<any>> = new Map();

export const CacheInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let uri = req.urlWithParams;
  if (req.method === "POST" && req.url.includes("utils/translate")) {
    if (Array.isArray(req.body)) {
      const sorted = req.body?.sort((a, b) => a.rowId - b.rowId);
      uri += `?${sorted[0].tableName}-${sorted[0].target}-${sorted[0].rowId}-${
        sorted[sorted.length - 1].rowId
      }`;
    } else {
      // @ts-ignore
      uri += `?${req.body?.target}-${req.body.tableName}-${req.body.rowId}`;
    }
  }
  if (req.method !== "GET" && !req.headers.get("no-cache")) {
    cache.clear();
    return next(req);
  }
  if (req.headers.get("X-Reset")) {
    cache.delete(uri);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cachedResponse: HttpResponse<any> | undefined = cache.get(uri);

  if (cachedResponse) {
    return of(cachedResponse.clone());
  } else {
    return next(req).pipe(
      tap(stateEvent => {
        if (stateEvent instanceof HttpResponse) {
          cache.set(uri, stateEvent.clone());
        }
      })
    );
  }
};
