import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-no-data",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./no-data.component.html",
  styles: [
    `
      :host {
        ::ng-deep {
          img.no-data-img {
            &.sm {
              @apply h-28;
            }
            &.md {
              @apply h-52;
            }
            &.lg {
              @apply h-96;
            }
          }
        }
      }
    `,
  ],
})
export class NoDataComponent {
  @Input() text: string | undefined;
  @Input() minHeight = true;
  @Input() imgSize: "sm" | "md" | "lg" = "md";

  @Input() imgSrc? = "/assets/icons/no-data.jpeg";
}
