import { HttpClient } from "@angular/common/http";
import { DestroyRef, inject, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { BackendResponse, Company, CompanyVerification } from "@models/models";
import { environment } from "@src/environments/environment";
import { first, tap } from "rxjs";

import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class VerifiedService {
  private readonly httpService = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly destroyRef = inject(DestroyRef);

  private readonly url = environment.backendUrl;
  readonly verificationRequest = signal<CompanyVerification | undefined>(undefined);

  loading = false;
  company?: Company;

  constructor() {
    this.authService.company.pipe(takeUntilDestroyed()).subscribe(val => {
      this.loading = true;
      this.company = val;
      if (val && val?.id) {
        this.getVerifyRequestCompanyId()
          .pipe(takeUntilDestroyed(this.destroyRef), first())
          .subscribe(() => (this.loading = false));
      }
    });
  }

  getVerifyRequest() {
    return this.httpService
      .get<BackendResponse<CompanyVerification>>(
        `${this.url}/company/verifications/${this.company?.verification?.id}`
      )
      .pipe(
        tap(val => {
          if (val.message === "success") {
            const company = JSON.parse(this.localStorageService.getData("company") ?? "{}");
            Object.assign(company, { verification: val.result });
            this.localStorageService.saveData("company", JSON.stringify(company));
            this.verificationRequest.set(val.result);
          }
        })
      );
  }

  getVerifyRequestCompanyId() {
    return this.httpService
      .get<BackendResponse<CompanyVerification>>(
        `${this.url}/company/verifications/company/${this.company?.id}`
      )
      .pipe(
        tap(val => {
          if (val.message === "success") {
            const company = JSON.parse(this.localStorageService.getData("company") ?? "{}");
            Object.assign(company, { verification: val.result });
            this.localStorageService.saveData("company", JSON.stringify(company));
            this.verificationRequest.set(val.result);
          }
        })
      );
  }

  createVerifyRequest(
    companyName: string,
    address: string,
    uId: string,
    uType: string,
    mediaIds: number[]
  ) {
    const data = { companyId: this.company?.id, companyName, address, uId, uType, mediaIds };
    const company = JSON.parse(this.localStorageService.getData("company") ?? "{}");
    if (this.verificationRequest()?.id)
      return this.httpService
        .put<BackendResponse<CompanyVerification>>(
          `${this.url}/company/verifications/${this.verificationRequest()?.id}`,
          data
        )
        .pipe(
          tap(val => {
            if (val.message === "success") {
              Object.assign(company, { verification: val.result });
              this.localStorageService.saveData("company", JSON.stringify(company));
              this.verificationRequest.set(val.result);
            }
          })
        );
    else
      return this.httpService
        .post<BackendResponse<CompanyVerification>>(`${this.url}/company/verifications`, data)
        .pipe(
          tap(val => {
            if (val.message === "success") {
              Object.assign(company, { verification: val.result });
              this.localStorageService.saveData("company", JSON.stringify(company));
              this.verificationRequest.set(val.result);
            }
          })
        );
  }
}
