import { inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { TranslationService } from "@services/translation.service";

@Injectable({
  providedIn: "root",
})
export class TooltipService implements OnDestroy {
  private tooltips: { [key: string]: HTMLDivElement } = {};
  private renderer: Renderer2;
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly translationService = inject(TranslationService);

  translations: Record<string, string> = {};

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.translationService
      .get("guides")
      .pipe(takeUntilDestroyed())
      .subscribe(val => {
        if (val) this.translations = val;
      });
  }

  private createTooltipElement(key: string, call?: () => void) {
    this.tooltips[key] = this.renderer.createElement("div");
    this.tooltips[key].tabIndex = -1;
    this.renderer.addClass(this.tooltips[key], "custom-tooltip");
    setTimeout(() => {
      this.tooltips[key].focus();
      // @ts-ignore
      this.tooltips[key].addEventListener("blur", () => {
        this.hideTooltip(key);
        call?.();
      });
    }, 1000);
    // const button = this.renderer.createElement("button");
    // this.tooltips[key].appendChild(button);
    this.renderer.appendChild(document.body, this.tooltips[key]);
  }

  ngOnDestroy(): void {
    for (const key in this.tooltips) {
      this.tooltips[key]?.remove();
    }
  }

  showTooltip(
    key: string,
    text: string,
    isTranslationKey: boolean,
    coordinates: { top: number; left: number },
    call?: () => void
  ): void {
    if (!this.tooltips[key]) {
      this.createTooltipElement(key, call);
    }

    this.renderer.setStyle(this.tooltips[key], "top", `${coordinates.top}px`);
    this.renderer.setStyle(this.tooltips[key], "left", `${coordinates.left}px`);
    this.renderer.addClass(this.tooltips[key], "show");
    // setTimeout(() => {
    //   console.log(this.tooltips[key].clientHeight, this.tooltips[key].clientWidth);
    //   this.renderer.setStyle(this.tooltips[key], "top", `${coordinates.top}px`);
    //   this.renderer.setStyle(this.tooltips[key], "left", `${coordinates.left}px`);
    // }, 500);
    if (!isTranslationKey) this.tooltips[key].textContent = text;
    else this.tooltips[key].textContent = this.translations[text];

    setTimeout(() => {
      this.hideTooltip(key);
    }, 5000);
  }

  hideTooltip(key: string): void {
    if (this.tooltips[key]) {
      this.renderer.removeClass(this.tooltips[key], "show");
    }
  }
}
