import { CommonModule, Location } from "@angular/common";
import { Component, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { SplitNavigateDirective } from "@directives/split-navigate.directive";
import { User } from "@models/models";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { iconoirNavArrowLeft } from "@ng-icons/iconoir";
import { TranslateModule } from "@ngx-translate/core";
import { SplitService } from "@services/split.service";
import { TranslationService } from "@services/translation.service";
import { WidthService } from "@services/width.service";
import { ButtonModule } from "primeng/button";
import { SkeletonModule } from "primeng/skeleton";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-main-title",
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    NgIconComponent,
    SkeletonModule,
    SplitNavigateDirective,
  ],
  providers: [provideIcons({ iconoirNavArrowLeft })],
  templateUrl: "./main-title.component.html",
})
export class MainTitleComponent implements OnChanges {
  @Input() user?: User;
  @Input() url: string | null = "";
  @Input() secondScreen = false;
  @Input() titleClass = "";

  private readonly translationService = inject(TranslationService);
  private readonly location = inject(Location);
  private readonly splitService = inject(SplitService);

  readonly widthService = inject(WidthService);

  mainTitle = "";
  translationLoading = false;
  translations$ = new BehaviorSubject<any>({});
  isBack = false;

  constructor() {
    this.translationLoading = true;
    this.translationService
      .get("page.app.sidebar")
      .pipe(takeUntilDestroyed())
      .subscribe({
        error: () => {
          this.translationLoading = false;
        },
        next: val => {
          this.translationLoading = false;
          this.translations$.next(val);
          if (this.url) {
            this.getTitle(this.url);
          }
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const url = changes["url"]?.currentValue;
    if (url) this.getTitle(url);
  }

  getTitle(url: string) {
    this.isBack = false;
    if (!this.secondScreen && url.split("/").length > 2) {
      this.isBack = true;
    }
    if (url.startsWith("/content")) {
      this.mainTitle = this.translations$.value["content"];
      if (url.split("/").length === 3 && url.split("/")[2] === "create") this.isBack = true;
    } else if (url.startsWith("/company")) this.mainTitle = this.translations$.value["company"];
    else if (url.startsWith("/collaborators"))
      this.mainTitle = this.translations$.value["collaborators"];
    else if (url.startsWith("/settings")) this.mainTitle = this.translations$.value["settings"];
    else if (url.startsWith("/task")) this.mainTitle = this.translations$.value["tasks"];
    else if (url.startsWith("/workspace")) this.mainTitle = this.translations$.value["workspace"];
    else if (url.startsWith("/messages")) this.mainTitle = this.translations$.value["messages"];
    else if (url.startsWith("/opportunities")) {
      if (url === "/opportunities") this.mainTitle = this.translations$.value["opportunities"];
      else if (url.split("/").length === 3) {
        this.mainTitle = this.translations$.value["applicant-list"];
      } else if (url.split("/").length === 4) {
        if (url.split("/")[3] === "create") {
          this.isBack = true;
          if (url.split("/")[2] === "job") {
            this.mainTitle = this.translations$.value["job-opportunity"];
          } else if (url.split("/")[2] === "exchange") {
            this.mainTitle = this.translations$.value["exchange-opportunity"];
          } else if (url.split("/")[2] === "service") {
            this.mainTitle = this.translations$.value["service-opportunity"];
          } else if (url.split("/")[2] === "event") {
            this.mainTitle = this.translations$.value["event-opportunity"];
          } else {
            this.mainTitle = this.translations$.value["opportunities"];
          }
        } else if (url.split("/")[2] === "detail") {
          this.isBack = true;
          this.mainTitle = this.translations$.value["opportunityDetail"];
        } else {
          this.isBack = true;
          this.mainTitle = this.translations$.value["applicantDetail"];
        }
      } else {
        this.isBack = true;
        this.mainTitle = this.translations$.value["task"];
      }
    } else if (url.startsWith("/profile")) this.mainTitle = this.translations$.value["profile"];
    else if (url.startsWith("/user-list")) {
      if (url.includes("group")) this.mainTitle = this.translations$.value["user-list-group"];
      else if (url.includes("filter"))
        this.mainTitle = this.translations$.value["advanced-user-search"];
      else this.mainTitle = this.translations$.value["user-list"];
    } else if (url.startsWith("/forum")) {
      this.mainTitle = this.translations$.value["forum"];
    } else if (url.startsWith("/community")) {
      this.mainTitle = this.translations$.value["forum"];
    } else if (url === "/" || url.startsWith("/?"))
      this.mainTitle = this.translations$.value["home"];
  }

  back() {
    if (this.secondScreen) {
      if (
        this.splitService.getSplitView()?.split("/")?.length === 4 &&
        this.splitService.getSplitView()?.split("/")[1] === "opportunities" &&
        this.splitService.getSplitView()?.split("/")[2] === "detail"
      ) {
        const id = this.splitService.getSplitView()?.split("/")[3];
        this.splitService.splitWithSize(["opportunities", id]);
      } else {
        const split = this.splitService.getSplitView().split("/");
        this.splitService.splitWithSize(split.slice(0, split.length - 1));
      }
    } else {
      this.location.back();
    }
  }
}
