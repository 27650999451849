<div class="relative h-[var(--dynamic-screen)] w-full bg-white pt-[56px] @container">
  <div
    class="h-full overflow-auto"
    [ngClass]="widthService.getSecondFullBody() ? '' : 'px-5 pt-5 @sm:px-10 @sm:pt-3'"
  >
    <div class="flex h-[56px] flex-row items-center justify-between gap-4 pr-4">
      <div class="mb-4">
        <app-main-title [url]="splitService.secondScreenListener | async" [secondScreen]="true" />
      </div>
      <p-button
        styleClass="p-button-outlined p-button-secondary p-button-rounded @md:normal-button small-button"
        icon="pi"
        (onClick)="splitService.clearSplit()"
      >
        <ng-icon name="iconoirCancel" class="text-2xl" />
      </p-button>
    </div>
    <div #view>
      <div
        *ngIf="loading"
        class="flex h-[calc(var(--dynamic-screen)-10rem)] items-center justify-center"
      >
        <p-progressSpinner />
      </div>
    </div>
  </div>
</div>
