import { Pipe, PipeTransform } from "@angular/core";

import { environment } from "@src/environments/environment";

@Pipe({
  name: "mediaUrl",
  standalone: true,
})
export class MediaUrlPipe implements PipeTransform {
  transform(value: string | undefined | null): string {
    if (!value) return "";
    return environment.cdnUrl + value;
  }
}
