import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[appScroll]",
  standalone: true,
})
export class ScrollDirective implements AfterViewInit {
  @Input() loaded = false;

  private readonly el: ElementRef<HTMLElement> = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  @HostListener("mouseenter") onMouseEnter() {
    this.showScrollbar();
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.hideScrollbar();
  }

  private showScrollbar() {
    if (this.isElementScrollable()) {
      this.renderer.removeClass(this.el.nativeElement, "no-scroll");
      this.renderer.addClass(this.el.nativeElement, "scroll");
    }
  }

  private hideScrollbar() {
    if (this.isElementScrollable()) {
      this.renderer.addClass(this.el.nativeElement, "no-scroll");
      this.renderer.removeClass(this.el.nativeElement, "scroll");
    }
  }

  private isElementScrollable() {
    return (
      this.el.nativeElement.scrollWidth > this.el.nativeElement.clientWidth ||
      this.el.nativeElement.scrollHeight > this.el.nativeElement.clientHeight
    );
  }

  ngAfterViewInit(): void {
    if (this.isElementScrollable()) {
      this.renderer.addClass(this.el.nativeElement, "no-scroll");
      this.renderer.addClass(this.el.nativeElement, "scroll-padding");
    }
  }
}
