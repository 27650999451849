import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";
import { inject, Injector } from "@angular/core";

import { Token } from "@models/models";
import { AuthService } from "@services/auth.service";
import { NetworkStatusService } from "@services/network-status.service";
import { SnackbarService } from "@services/snackbar.service";
import {
  BehaviorSubject,
  catchError,
  concat,
  filter,
  first,
  ignoreElements,
  Observable,
  switchMap,
  take,
  throwError,
} from "rxjs";

let isRefreshing = false;
const accessTokenSubject = new BehaviorSubject<string>("");

const handleError = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  error: HttpErrorResponse,
  injector: Injector
) => {
  if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 498)) {
    const authService = injector.get(AuthService);
    const snackbarService = injector.get(SnackbarService);
    if (!req.headers.get("no-authorization")) {
      return handle401Error(authService, req, next);
    } else {
      let message = "Error";
      if (error.error.message) {
        message = error.error.message;
        if (!req.headers.get("no-error-message")) {
          try {
            snackbarService.error(message);
          } catch (err) {
            alert(message);
          }
        }
      }
      return throwError(() => new Error(message));
    }
  } else {
    const snackbarService = injector.get(SnackbarService);
    let message = "Error";
    if (error.error.message) {
      message = error.error.message;
      if (!req.headers.get("no-error-message")) {
        try {
          snackbarService.error(message);
        } catch (err) {
          alert(message);
        }
      }
    }
    return throwError(() => new Error(message));
  }
};

const addToken = (request: HttpRequest<any>, token: string) => {
  return request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const handle401Error = (
  authService: AuthService,
  request: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  if (!isRefreshing) {
    isRefreshing = true;
    accessTokenSubject.next("");

    return authService.refresh().pipe(
      switchMap((res: Token) => {
        isRefreshing = false;
        accessTokenSubject.next(res.accessToken);
        authService.loginWithToken({
          accessToken: res.accessToken,
          refreshToken: authService.refreshToken.value,
          user: authService.getUserValue(),
        });
        return next(addToken(request, res.accessToken));
      }),
      catchError(err => {
        isRefreshing = false;
        authService.logout();
        return throwError(() => new Error(err));
      })
    );
  }

  return accessTokenSubject.pipe(
    filter(token => token !== ""),
    take(1),
    switchMap(jwt => next(addToken(request, jwt)))
  );
};

export const ErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const injector = inject(Injector);
  const networkStatusService = inject(NetworkStatusService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (!error.error?.statusCode) {
        return concat(
          networkStatusService.requestQueue$.pipe(first(), ignoreElements()),
          next(req).pipe(
            catchError((err: HttpErrorResponse) => handleError(req, next, err, injector))
          )
        );
      } else return handleError(req, next, error, injector);
    })
  );
};
