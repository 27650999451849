import { HttpClient } from "@angular/common/http";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { BackendResponse } from "@models/models";
import { Translation } from "@models/translations";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@src/environments/environment";
import { BehaviorSubject, first, map, merge, Observable, of } from "rxjs";

import { LocalStorageService } from "./local-storage.service";

interface TranslationDto {
  text?: string;
  rowId: number;
  tableName: string;
  target?: string;
}

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private readonly httpService = inject(HttpClient);

  private readonly destroyRef = inject(DestroyRef);
  private readonly translateService = inject(TranslateService);
  private readonly localStorageService = inject(LocalStorageService);

  private currentLang = new BehaviorSubject<string>(
    this.localStorageService.getData("lang") ?? "en"
  );
  private readonly url = environment.backendUrl;

  currentLangListener = this.currentLang.asObservable();

  constructor() {
    this.translateService.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(val => {
      if (this.currentLang.value !== val.lang) {
        this.currentLang.next(val.lang);
        setTimeout(() => {
          this.localStorageService.saveData("lang", val.lang);
        }, 1000);
      }
    });
  }

  private getValueFromString = (obj: any, key: string) => {
    const keys = key.split(".");
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
      if (!value) {
        break;
      }
    }
    return value ?? "";
  };

  getCurrentLanguage() {
    return this.currentLang.value;
  }

  get(path: string | string[]) {
    if (typeof path === "string")
      return merge(
        this.translateService.get(path).pipe(takeUntilDestroyed(this.destroyRef), first()),
        this.translateService.onLangChange.pipe(
          takeUntilDestroyed(this.destroyRef),
          map(trans => this.getValueFromString(trans.translations, path))
        )
      );
    return merge(
      this.translateService.get(path).pipe(takeUntilDestroyed(this.destroyRef), first()),
      this.translateService.onLangChange.pipe(
        takeUntilDestroyed(this.destroyRef),
        map(trans => path.map(pa => this.getValueFromString(trans.translations, pa)))
      )
    );
  }

  changeLanguage(lang: string, data: any, tableName: string): Observable<any> {
    const extractItems = (inputData: any): any[] => {
      if (Array.isArray(inputData) && inputData.length > 0 && Array.isArray(inputData[0]))
        return inputData.reduce((pre, cur) => [...cur, ...pre], []);
      if (Array.isArray(inputData?.result)) return inputData.result;
      if (Array.isArray(inputData?.result?.items)) return inputData.result?.items;
      if (Array.isArray(inputData)) return inputData;
      return [];
    };

    const items = extractItems(data);

    const shouldTranslate = items
      .filter(item => item.defaultLanguageCode !== lang)
      .map(op => ({ rowId: op.id, tableName: tableName, target: lang }));
    if (shouldTranslate.length)
      return this.listUtilTranslationFromBackend(shouldTranslate).pipe(
        takeUntilDestroyed(this.destroyRef),
        first(),
        map(val => {
          if (val.message === "success") {
            const translateItem = (item: any) => {
              const translation = val.result.find(({ rowId }) => item.id === rowId);
              return { ...item, translated: translation?.translations ?? undefined };
            };

            if (Array.isArray(data)) {
              if (data.length > 0 && Array.isArray(data[0])) {
                const translated = data.map(dat => dat.map((op: any) => translateItem(op)));
                return translated;
              }
              const translated = data?.map(dat => translateItem(dat));
              return translated;
            } else {
              if (Array.isArray(data?.result)) {
                return {
                  ...data,
                  result: items.map(op => translateItem(op)),
                };
              }
              return {
                ...data,
                result: { items: items.map(op => translateItem(op)), meta: data.result?.meta },
              };
            }
          } else {
            return data;
          }
        })
      );
    return of(data);
  }

  changeLanguageSingle(lang: string, data: any, tableName: string): Observable<any> {
    const extractItem = (inputData: any): any => {
      if (inputData?.id) return inputData;
      if (inputData?.result?.id) return inputData.result;
      return undefined;
    };

    const item = extractItem(data);
    if (!item) return of({ message: "error" });
    if (item.defaultLanguageCode === lang) return of(data);
    return this.listUtilTranslationFromBackend({
      rowId: item.id,
      tableName: tableName,
      target: lang,
    }).pipe(
      takeUntilDestroyed(this.destroyRef),
      first(),
      map(val => {
        if (val.message === "success") {
          if (data?.id)
            return {
              ...item,
              translated: {
                // @ts-ignore
                ...(val.result?.translations ?? {}),
                // @ts-ignore
                languageCode: val.result?.languageCode,
              },
            };
          else
            return {
              message: "success",
              result: {
                ...item,
                // @ts-ignore
                translated: {
                  // @ts-ignore
                  ...(val.result?.translations ?? {}),
                  // @ts-ignore
                  languageCode: val.result?.languageCode,
                },
              },
            };
        } else {
          return data;
        }
      })
    );
  }

  listTranslationFromBackend(rowId: number, tableName: string) {
    return this.httpService.post<BackendResponse<Translation[]>>(
      `${this.url}/translations/search`,
      {
        rowId,
        tableName,
      }
    );
  }

  listUtilTranslationFromBackend(data: TranslationDto[] | TranslationDto) {
    return this.httpService.post<BackendResponse<Translation[]>>(
      `${this.url}/utils/translate`,
      data,
      {
        headers: {
          "no-cache": "true",
        },
      }
    );
  }

  getTranslationFromBackend(id: number) {
    return this.httpService.get<BackendResponse<Translation>>(`${this.url}/translations/${id}`);
  }

  postTranslation(
    rowId: number,
    tableName: string,
    languageCode: string,
    translations: Record<string, string>
  ) {
    return this.httpService.post<BackendResponse<Translation>>(`${this.url}/translations`, {
      rowId,
      tableName,
      languageCode,
      translations,
    });
  }

  editTranslation(
    id: number,
    rowId: number,
    tableName: string,
    languageCode: string,
    translations: Record<string, string>
  ) {
    return this.httpService.put<BackendResponse<Translation>>(`${this.url}/translations/${id}`, {
      rowId,
      tableName,
      languageCode,
      translations,
    });
  }
}
