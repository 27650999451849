import { inject, Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";

import { AuthService } from "@services/auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CompanyGuard implements CanActivate {
  private readonly authService = inject(AuthService);
  private readonly routerService = inject(Router);

  canActivate(): // route: ActivatedRouteSnapshot
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getCompanyValue()?.id) {
      return true;
    }
    this.routerService.navigate(["company"]);
    return false;
  }
}
