import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";

import { environment } from "@src/environments/environment";
import { map } from "rxjs";

import {
  BackendResponse,
  Content,
  Industry,
  Media,
  MetaCountBackendResponse,
  Notification,
  Skill,
} from "../models/models";

@Injectable({
  providedIn: "root",
})
export class BackendService {
  private readonly httpService = inject(HttpClient);

  private readonly url = environment.backendUrl;

  getNotifications(page: number, limit: number) {
    return this.httpService.get<MetaCountBackendResponse<Notification>>(
      `${this.url}/notification?page=${page}&limit=${limit}`
    );
  }

  getSingleNotification(id: number) {
    return this.httpService.get<BackendResponse<Notification>>(`${this.url}/notification/${id}`);
  }

  getAllContents(page: number, limit: number) {
    return this.httpService.get<MetaCountBackendResponse<Content>>(
      `${this.url}/contents/individual/list?page=${page}&limit=${limit}&orderBy=createdAt&orderDirection=DESC`
    );
  }

  // Content
  getContents(page: number, limit: number, name: string, status: string) {
    return this.httpService.get<MetaCountBackendResponse<Content>>(
      `${this.url}/contents?page=${page}&limit=${limit}&name=${name}&status=${status}&orderBy=createdAt&orderDirection=DESC`
    );
  }
  getContent(id: string | number) {
    return this.httpService.get<BackendResponse<Content>>(`${this.url}/contents/${id}`).pipe(
      map(val => {
        return val;
      })
    );
  }
  createContent(data: Content) {
    return this.httpService.post<BackendResponse<Content>>(`${this.url}/contents`, data);
  }
  getFieldsFromExternal(link: string) {
    return this.httpService.post<
      BackendResponse<{ title?: string; description?: string; image?: string }>
    >(`${this.url}/contents/external`, { link });
  }
  createContentMediaWithLink(data: { contentId: number; url: string; title: string }) {
    return this.httpService.post<BackendResponse<Media>>(
      `${this.url}/contents/external/media`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
  editContent(data: Content, id: string | number) {
    return this.httpService.put<BackendResponse<Content>>(`${this.url}/contents/${id}`, data);
  }
  deleteContent(id: number) {
    return this.httpService.delete<BackendResponse<Content>>(`${this.url}/contents/${id}`);
  }

  getIndustries() {
    return this.httpService.get<BackendResponse<Industry[]>>(`${this.url}/industries`);
  }
  getSkills() {
    return this.httpService.get<BackendResponse<Skill[]>>(`${this.url}/skills`);
  }
  getLanguages() {
    return this.httpService.get<BackendResponse<Industry[]>>(`${this.url}/languages`);
  }
}
