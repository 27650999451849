<div
  class="flex select-none flex-col items-center justify-center gap-0"
  [ngClass]="{ 'min-h-[385px]': minHeight }"
>
  <img
    class="no-data-img"
    alt="no-data"
    [src]="imgSrc ?? '/assets/icons/no-data.jpeg'"
    [ngClass]="imgSize"
  />
  <span class="text-center text-lg">
    {{ text ?? ("common.no-data" | translate) }}
  </span>
  <ng-content />
</div>
