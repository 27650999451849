import { inject, Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";

import { VerifiedService } from "@services/verified.service";
import { catchError, map, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class VerifiedGuard implements CanActivate {
  private readonly verificationService = inject(VerifiedService);
  private readonly routerService = inject(Router);

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.verificationService.loading)
      return this.verificationService.getVerifyRequest().pipe(
        map(val => val.message === "success" && val.result.status === "approved"),
        catchError(() => {
          this.routerService.navigate(["company", "verify"]);
          return of(false);
        })
      );
    if (this.verificationService.verificationRequest()?.status === "approved") {
      return true;
    }
    this.routerService.navigate(["company", "verify"]);
    return false;
  }
}
