export const environment = {
  production: true,
  backendUrl: "https://dev.ionsapp.com/api/v1",
  cdnUrl: "https://cdn.ionsapp.com/",
  websocket: "wss://dev.ionsapp.com/",
  landingUrl: "https://www.ionsapp.com",
  mapbox: {
    accessToken:
      "pk.eyJ1IjoiZW5naW5lZXJpbmdhZG1pbiIsImEiOiJjbG8zOGpiMWcwajYzMnV1ZDJha3NkczllIn0.ZszDZ1sMgoYRUr6UNCwELw",
    secretToken:
      "sk.eyJ1IjoiZW5naW5lZXJpbmdhZG1pbiIsImEiOiJjbGozZTA4bnMwcHgzM2dvZW8yaXB3MnpwIn0.680itCBt-dRYkBvD-acOmw",
    style: "mapbox://styles/engineeringadmin/clj34kg9f01i101pfdpu3ct8p",
  },
  encryptionKey: "KPXllZE479bt/SCbznJnLumEsS6/olDGnjrtS00DS85bnP.SRBNOC",
};
