import { isPlatformBrowser } from "@angular/common";
import { inject, Injectable, PLATFORM_ID } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WidthService {
  private readonly platformId = inject(PLATFORM_ID);

  private innerWidth = new BehaviorSubject<number>(0);
  private bodyFull = new BehaviorSubject<boolean>(false);
  private secondBodyFull = new BehaviorSubject<boolean>(false);

  // private secondScreenSize = new BehaviorSubject<string>("lg");
  // private secondScreenSizeLevel = new BehaviorSubject<number>(1);

  innerWidth$: Observable<number> = this.innerWidth;
  bodyFull$: Observable<boolean> = this.bodyFull;
  secondBodyFull$: Observable<boolean> = this.secondBodyFull;

  constructor() {
    this.checkWidth();
  }

  changeFullBody(value: boolean, force?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      if (force) this.bodyFull.next(value);
      else {
        const url = window.location.pathname;
        if (url.split("/")[1] === "messages" || url.split("/")[1] === "forum") {
          if (!this.bodyFull.value || (!this.bodyFull.value && value)) {
            this.bodyFull.next(value);
          }
        } else this.bodyFull.next(value);
      }
    }
  }

  checkWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth.next(window.innerWidth);
    }
  }

  getWidth() {
    return this.innerWidth.value;
  }

  getFullBody() {
    return this.bodyFull.value;
  }

  getSecondFullBody() {
    return this.secondBodyFull.value;
  }

  changeSecondFullBody(value: boolean) {
    this.secondBodyFull.next(value);
  }
}
