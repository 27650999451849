import { inject, Pipe, PipeTransform } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { TranslationService } from "@services/translation.service";

// Array mapping month numbers to month names
const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

@Pipe({
  name: "formatDate",
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  private readonly translationService = inject(TranslationService);

  translation: any = {};

  constructor() {
    this.translationService
      .get("dates")
      .pipe(takeUntilDestroyed())
      .subscribe(val => {
        this.translation = val;
      });
  }

  transform(
    value?: string,
    options: { showHour?: boolean; showMonthAsWord?: boolean; short?: boolean } = {}
  ): string {
    if (!value) return "";

    const date = new Date(value);
    const now = new Date();

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    const currentDay = now.getDate();

    const showHour = options.showHour ?? false;
    const showMonthAsWord = options.showMonthAsWord ?? false;

    let monthWord = MONTH_NAMES[month - 1];
    if (options.short) monthWord = monthWord.substring(0, 3);

    if (year === currentYear && month === currentMonth && day === currentDay) {
      return showHour
        ? `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
        : this.translation["today"];
    } else if (year === currentYear) {
      // const monthStr = showMonthAsWord ? monthNames : String(month).padStart(2, '0');
      const monthStr = monthWord;
      return showHour
        ? `${monthStr} ${String(day).padStart(2, "0")} ${String(hours).padStart(2, "0")}:${String(
            minutes
          ).padStart(2, "0")}`
        : `${monthStr} ${String(day).padStart(2, "0")}`;
    } else {
      const monthStr = showMonthAsWord ? monthWord : String(month).padStart(2, "0");
      return showHour
        ? `${year}-${monthStr}-${String(day).padStart(2, "0")} ${String(hours).padStart(
            2,
            "0"
          )}:${String(minutes).padStart(2, "0")}`
        : `${year}-${monthStr}-${String(day).padStart(2, "0")}`;
    }
  }
}
