import { Injectable, signal } from "@angular/core";

import { BehaviorSubject, filter } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NetworkStatusService {
  private readonly requestQueue = new BehaviorSubject<boolean>(true);

  isOnline = signal<boolean>(true);

  get requestQueue$() {
    return this.requestQueue.pipe(filter(Boolean));
  }

  setOnline(value: boolean) {
    this.requestQueue.next(value);
    this.isOnline.set(value);
  }
}
