import { CommonModule } from "@angular/common";
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { MainTitleComponent } from "@common/main-title/main-title.component";
import { ScrollDirective } from "@directives/scroll.directive";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { iconoirCancel } from "@ng-icons/iconoir";
import { SplitService } from "@services/split.service";
import { WidthService } from "@services/width.service";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from "primeng/progressspinner";

@Component({
  selector: "app-split-view",
  standalone: true,
  imports: [
    CommonModule,
    MainTitleComponent,
    ButtonModule,
    NgIconComponent,
    ProgressSpinnerModule,
    ScrollDirective,
  ],
  providers: [provideIcons({ iconoirCancel })],
  templateUrl: "./split-view.component.html",
})
export class SplitViewComponent implements AfterContentInit, OnDestroy {
  @ViewChild("view", { read: ViewContainerRef }) view!: ViewContainerRef;

  private readonly changeDetectionRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  readonly splitService = inject(SplitService);
  readonly widthService = inject(WidthService);

  componentRef: any;
  current = "";
  loading = false;

  ngAfterContentInit(): void {
    this.splitService.secondScreenListener
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(val => this.loadComponent(val));
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
    this.view.clear();
    setTimeout(() => this.widthService.changeFullBody(false), 0);
  }

  loadComponent(val: string) {
    this.changeDetectionRef.detectChanges();
    this.loading = true;
    if (val.startsWith("/")) {
      if (val[1] === "/") val = val.substring(2);
      else val = val.substring(1);
    }
    val = "/" + val;
    if (val.includes("?")) val = val.split("?")[0];
    if (!val.startsWith("/messages") || !this.current.startsWith("/messages")) {
      this.view.clear();
      this.componentRef?.destroy();
      if (val === "/") {
        import("../home/home.component")
          .then(m => {
            this.loading = false;
            this.componentRef = this.view.createComponent(m.HomeComponent);
          })
          .catch(() => (this.loading = false));
      } else if (val.startsWith("/opportunities")) {
        if (val === "/opportunities")
          import("../opportunities/opportunities.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.OpportunitiesComponent);
            })
            .catch(() => (this.loading = false));
        else if (val.split("/").length === 3) {
          import("../opportunities/single-opportunity/single-opportunity.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.SingleOpportunityComponent);
              this.componentRef.instance.secondScreen = true;
              this.componentRef.instance.opportunityId = +val.split("/")[2];
            })
            .catch(() => (this.loading = false));
        } else if (val.split("/").length === 4) {
          if (val.split("/")[2] === "detail") {
            import("../opportunities/single-opportunity/detail/single-opportunity-detail.component")
              .then(m => {
                this.loading = false;
                this.componentRef = this.view.createComponent(m.SingleOpportunityDetailComponent);
                this.componentRef.instance.secondScreen = true;
                this.componentRef.instance.opportunityId = +val.split("/")[3];
              })
              .catch(() => (this.loading = false));
          } else {
            import("../opportunities/applicant-single/applicant-single.component")
              .then(m => {
                this.loading = false;
                this.componentRef = this.view.createComponent(m.ApplicantSingleComponent);
                this.componentRef.instance.secondScreen = true;
                this.componentRef.instance.opportunityId = +val.split("/")[2];
                this.componentRef.instance.applicationId = +val.split("/")[3];
              })
              .catch(() => (this.loading = false));
          }
        } else {
          if (val.endsWith("create")) {
            import("../task/create-task/create-task.component")
              .then(m => {
                this.loading = false;
                this.componentRef = this.view.createComponent(m.CreateTaskComponent);
                this.componentRef.instance.secondScreen = true;
                this.componentRef.instance.opportunityId = +val.split("/")[2];
                this.componentRef.instance.applicationId = +val.split("/")[3];
              })
              .catch(() => (this.loading = false));
          }
        }
      } else if (val.startsWith("/task")) {
        if (val.split("/").length === 3) {
          import("../task/task-single/task-single.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.TaskSingleComponent);
              this.componentRef.instance.secondScreen = true;
              this.componentRef.instance.taskId = +val.split("/")[2];
            })
            .catch(() => (this.loading = false));
        } else if (val.split("/").length === 4 && val.split("/")[2] === "common") {
          import("../task/common-task-single/common-task-single.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.CommonTaskSingleComponent);
              this.componentRef.instance.secondScreen = true;
              this.componentRef.instance.taskId = +val.split("/")[3];
            })
            .catch(() => (this.loading = false));
        } else {
          import("../tasks/tasks.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.TasksComponent);
            })
            .catch(() => (this.loading = false));
        }
      } else if (val.startsWith("/content")) {
        if (val === "/content") {
          import("../content/content.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.ContentComponent);
              this.componentRef.instance.secondScreen = true;
            })
            .catch(() => (this.loading = false));
        } else if (val === "/content/create") {
          import("../content/create-content/create-content.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.CreateContentComponent);
              this.componentRef.instance.secondScreen = true;
            })
            .catch(() => (this.loading = false));
        } else {
          import("../content/single-content/single-content.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.SingleContentComponent);
              this.componentRef.instance.contentId = parseInt(val.split("/")[2]);
              this.componentRef.instance.secondScreen = true;
            })
            .catch(() => (this.loading = false));
        }
      } else if (val.startsWith("/collaborators")) {
        import("../collaborators/collaborators.component")
          .then(m => {
            this.loading = false;
            this.componentRef = this.view.createComponent(m.CollaboratorsComponent);
          })
          .catch(() => (this.loading = false));
      } else if (val.startsWith("/company")) {
        import("../company/company.component")
          .then(m => {
            this.loading = false;
            this.componentRef = this.view.createComponent(m.CompanyComponent);
          })
          .catch(() => (this.loading = false));
      } else if (val.startsWith("/community")) {
        if (val.split("/").length === 3) {
          if (val.split("/")[2] === "create-community") {
            import("../forum/create-community/create-community.component")
              .then(m => {
                this.loading = false;
                this.componentRef = this.view.createComponent(m.CreateCommunityComponent);
                this.componentRef.instance.secondScreen = true;
              })
              .catch(() => (this.loading = false));
          } else {
            import("../forum/single-community/single-community.component")
              .then(m => {
                this.loading = false;
                this.componentRef = this.view.createComponent(m.SingleCommunityComponent);
                this.componentRef.instance.secondScreen = true;
                this.componentRef.instance.communityId = +val.split("/")[2];
              })
              .catch(() => (this.loading = false));
          }
        } else {
          this.splitService.splitWithSize(["forum"], { activeIndex: 1 });
        }
      } else if (val.startsWith("/forum")) {
        if (val.split("/").length === 2) {
          import("../forum/forum.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.ForumComponent);
              this.componentRef.instance.secondScreen = true;
            })
            .catch(() => (this.loading = false));
        } else if (val.split("/").length === 3) {
          if (val.split("/")[2] === "create-forum") {
            import("../forum/create-forum/create-forum.component")
              .then(m => {
                this.loading = false;
                this.componentRef = this.view.createComponent(m.CreateForumComponent);
                this.componentRef.instance.secondScreen = true;
              })
              .catch(() => (this.loading = false));
          } else {
            import("../forum/single-post/single-post.component")
              .then(m => {
                this.loading = false;
                this.componentRef = this.view.createComponent(m.SinglePostComponent);
                this.componentRef.instance.secondScreen = true;
                this.componentRef.instance.forumId = +val.split("/")[2];
              })
              .catch(() => (this.loading = false));
          }
        }
      } else if (val.startsWith("/user-list")) {
        if (val.split("/")[2] === "group") {
          import("../user-list/group-single/group-single.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.GroupSingleComponent);
              this.componentRef.instance.secondScreen = true;
              this.componentRef.instance.groupId = +val.split("/")[3];
            })
            .catch(() => (this.loading = false));
        } else {
          import("../user-list/user-single/user-single.component")
            .then(m => {
              this.loading = false;
              this.componentRef = this.view.createComponent(m.UserSingleComponent);
              this.componentRef.instance.secondScreen = true;
              this.componentRef.instance.userId = +val.split("/")[3];
            })
            .catch(() => (this.loading = false));
        }
      } else if (val.startsWith("/messages")) {
        // if (this.splitService.getSplitView() !== val)
        import("../messages/messages.component")
          .then(m => {
            this.loading = false;
            this.componentRef = this.view.createComponent(m.MessagesComponent);
            this.componentRef.instance.secondScreen = true;
          })
          .catch(() => (this.loading = false));
      }
      this.current = val;
    } else {
      this.loading = false;
    }
  }
}
