import { isPlatformBrowser } from "@angular/common";
import { inject, Injectable, PLATFORM_ID } from "@angular/core";

function objectsAreEqual(objA: Record<any, any>, objB: Record<any, any>) {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (typeof objA[key] === "object" && typeof objB[key] === "object") {
      if (!objectsAreEqual(objA[key], objB[key])) {
        return false;
      }
    } else if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
}

function arraysAreEqual(arrA: any[], arrB: any[]) {
  if (arrA.length !== arrB.length) {
    return false;
  }

  for (let i = 0; i < arrA.length; i++) {
    if (Array.isArray(arrA[i]) && Array.isArray(arrB[i])) {
      if (!arraysAreEqual(arrA[i], arrB[i])) {
        return false;
      }
    } else if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
}

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private platformId = inject(PLATFORM_ID);

  isBrowser = false;

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }

  saveData(key: string, value: string) {
    if (this.isBrowser) localStorage.setItem(key, value);
    return true;
  }

  getData(key: string) {
    if (this.isBrowser) return localStorage.getItem(key);
    return undefined;
  }
  removeData(key: string) {
    if (this.isBrowser) localStorage.removeItem(key);
  }

  clearData() {
    if (this.isBrowser) localStorage.clear();
  }

  compareData(key: string, data: any) {
    const parsedData = JSON.parse(this.getData(key) ?? "");
    if (typeof parsedData === "object") {
      if (Array.isArray(parsedData)) return arraysAreEqual(parsedData, data);
      else return objectsAreEqual(parsedData, data);
    } else return parsedData === data;
  }
}
