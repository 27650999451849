import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withComponentInputBinding } from "@angular/router";

import { GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
import { TooltipService } from "@common/tooltip.service";
import { CacheInterceptor } from "@interceptors/cache.interceptor";
import { ErrorInterceptor } from "@interceptors/error.interceptor";
import { TokenInterceptor } from "@interceptors/token.interceptor";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AuthService } from "@services/auth.service";
import { NetworkStatusService } from "@services/network-status.service";
import { TaskService } from "@services/task.service";
import { TranslationService } from "@services/translation.service";
import { VerifiedService } from "@services/verified.service";
import { WorkspaceService } from "@services/workspace.service";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { ConfirmationService, MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";

import { routes } from "./app.routes";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideEnvironmentNgxMask(() => ({ validation: false })),
    provideAnimations(),
    MessageService,
    AuthService,
    WorkspaceService,
    ConfirmationService,
    NetworkStatusService,
    TranslationService,
    VerifiedService,
    TaskService,
    TooltipService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "289362504176-dq47qj5f1gsoqor00go6bvb6ggth0ci8.apps.googleusercontent.com",
              {
                oneTapEnabled: false,
                scopes: ["https://www.googleapis.com/auth/userinfo.email"],
              }
            ),
          },
        ],
        onError: err => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: "en",
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      ToastModule
    ),
    provideHttpClient(withInterceptors([TokenInterceptor, ErrorInterceptor, CacheInterceptor])),
  ],
};
