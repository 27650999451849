import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";

import { AuthService } from "@services/auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  private readonly authService = inject(AuthService);
  private readonly routerService = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuth = this.authService.autoLogin();
    if (
      isAuth &&
      (route.url.join("").startsWith("login") ||
        route.url.join("").startsWith("signup") ||
        route.url.join("").startsWith("forgot-password"))
    ) {
      this.routerService.navigate([""]);
      return true;
    }
    if (
      !isAuth &&
      !(
        route.url.join("").startsWith("login") ||
        route.url.join("").startsWith("signup") ||
        route.url.join("").startsWith("forgot-password")
      )
    ) {
      this.routerService.navigate(["login"]);
      return false;
    }
    return true;
  }
}
