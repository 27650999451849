import { HttpClient } from "@angular/common/http";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";

import { BackendResponse, MetaCountBackendResponse } from "@models/models";
import { environment } from "@src/environments/environment";
import queryString from "query-string";
import { BehaviorSubject, first, tap } from "rxjs";

import { Task, TaskResponse, TaskResponseMedia } from "../models/tasks";
import { SnackbarService } from "./snackbar.service";
import { SplitService } from "./split.service";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  private readonly httpService = inject(HttpClient);
  private readonly routerService = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly splitService = inject(SplitService);
  private readonly snackbarService = inject(SnackbarService);

  private readonly url = environment.backendUrl;

  loadingDoneTasks = false;
  doneCommonTasks = new BehaviorSubject<string[]>([]);

  constructor() {
    this.getDoneCommonTasks()
      .pipe(takeUntilDestroyed(), first())
      .subscribe(val => {
        if (val.message === "success") {
          this.doneCommonTasks.next(val.result);
        }
      });
  }

  createTask(data: Task) {
    return this.httpService.post<BackendResponse<Task>>(`${this.url}/tasks`, data);
  }

  editTask(id: number, data: Partial<Task>) {
    return this.httpService.put<BackendResponse<Task>>(`${this.url}/tasks/${id}`, data);
  }

  deleteTask(id: number) {
    return this.httpService.delete<BackendResponse<Task>>(`${this.url}/tasks/${id}`);
  }

  getTask(id: number) {
    return this.httpService.get<BackendResponse<Task>>(`${this.url}/tasks/enterprise/${id}`);
  }

  getCommonTask(id: number) {
    return this.httpService.get<BackendResponse<Task>>(`${this.url}/tasks/${id}`);
  }

  getOwnTasks(
    page: number,
    limit: number,
    title?: string,
    status?: string,
    priorityLevel?: string,
    orderBy?: string,
    orderDirection?: string,
    month?: string,
    date?: string
  ) {
    const data = {
      page,
      limit,
      title,
      status,
      priorityLevel,
      orderBy,
      orderDirection,
      month,
      date,
    };
    return this.httpService.get<MetaCountBackendResponse<Task>>(
      `${this.url}/tasks/own?${queryString.stringify(data)}`
    );
  }

  createTaskResponse(id: number, comment: string) {
    return this.httpService.post<BackendResponse<TaskResponse>>(`${this.url}/task/responses`, {
      taskId: id,
      comment,
    });
  }
  createTaskResponseMedia(id: number, mediaId: number) {
    return this.httpService.post<BackendResponse<TaskResponseMedia>>(
      `${this.url}/task/response/medias`,
      {
        taskResponseId: id,
        mediaId,
      }
    );
  }

  getCommonTasks(level?: number) {
    const data = queryString.stringify({ level });
    return this.httpService.get<BackendResponse<Task[]>>(`${this.url}/tasks/common?${data}`);
  }

  getDoneCommonTasks() {
    return this.httpService
      .get<BackendResponse<string[]>>(`${this.url}/task/responses/common`)
      .pipe(
        tap(val => {
          if (val.message === "success") {
            if (this.doneCommonTasks.value.length) {
              if (
                !val.result.every(va => this.doneCommonTasks.value.some(val => va === val)) &&
                this.doneCommonTasks.value.length
              ) {
                this.doneCommonTasks.next(val.result);
                this.snackbarService.success("", "", "common-task-done");
              }
            } else {
              this.doneCommonTasks.next(val.result);
            }
          }
        })
      );
  }

  finishCommonTaskObservable(shortCode: string) {
    return this.httpService
      .post<BackendResponse<TaskResponse>>(`${this.url}/task/responses/common`, {
        shortCode,
      })
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  finishCommonTask(shortCode: string) {
    if (!this.doneCommonTasks.value.includes(shortCode)) {
      this.loadingDoneTasks = true;
      this.httpService
        .post<BackendResponse<TaskResponse>>(`${this.url}/task/responses/common`, {
          shortCode,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(val => {
          if (val.message === "success")
            this.getDoneCommonTasks()
              .pipe(takeUntilDestroyed(this.destroyRef), first())
              .subscribe(() => (this.loadingDoneTasks = false));
        });
    }
  }

  handleCommonTask(code?: string) {
    switch (code) {
      case "changeLanguage":
        this.routerService.navigate([""], {
          skipLocationChange: true,
          queryParams: { changeLanguage: true, skipUrl: true },
        });
        break;
      case "changeUserAvatar":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["profile"], {
          skipLocationChange: true,
          queryParams: { changeUserAvatar: true },
        });
        break;
      case "seeOtherContent":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate([""], {
          skipLocationChange: true,
          queryParams: { seeOtherContent: true },
        });
        break;
      case "createYourOwnTask":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["tasks"], {
          skipLocationChange: true,
          queryParams: { seeOtherContent: true },
        });
        break;
      case "addInternalMember":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["company"], {
          skipLocationChange: true,
          queryParams: { addInternalMember: true },
        });
        break;
      case "seeForums":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"]);
        break;
      case "navigateToSingleForum":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"], {
          skipLocationChange: true,
          queryParams: { navigateToSingleForum: true },
        });
        break;
      case "verifyCompany":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["company", "verify"]);
        break;
      case "interactWithForum":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"], {
          skipLocationChange: true,
          queryParams: { interactWithForum: true },
        });
        break;
      case "createYourOwnForum":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"], {
          skipLocationChange: true,
          queryParams: { createYourOwnForum: true },
        });
        break;
      case "createForumWithPoll":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"], {
          skipLocationChange: true,
          queryParams: { createForumWithPoll: true },
        });
        break;
      case "seeCreatedForum":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"], {
          skipLocationChange: true,
          queryParams: { activeIndex: 2 },
        });
        break;
      case "createYourOwnCommunity":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"], {
          skipLocationChange: true,
          queryParams: { activeIndex: 1, createYourOwnCommunity: true },
        });
        break;
      case "seeYourOwnCommunity":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["forum"], {
          skipLocationChange: true,
          queryParams: { activeIndex: 1, role: "admin" },
        });
        break;
      case "createYourOwnContent":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["content"], {
          skipLocationChange: true,
          queryParams: { createYourOwnContent: true },
        });
        break;
      case "createYourOwnWorkOpportunity":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["opportunities"], {
          skipLocationChange: true,
          queryParams: { createYourOwnWorkOpportunity: true },
        });
        break;
      case "seeApplicantList":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["opportunities"], {
          skipLocationChange: true,
          queryParams: { seeApplicantList: true },
        });
        break;
      case "addTranslationToOpportunity":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["opportunities"], {
          skipLocationChange: true,
          queryParams: { addTranslationToOpportunity: true },
        });
        break;
      case "addCollaboratorsToOpportunity":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["opportunities"], {
          skipLocationChange: true,
          queryParams: { addCollaboratorsToOpportunity: true },
        });
        break;
      case "assignTaskToApplicant":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["opportunities"], {
          skipLocationChange: true,
          queryParams: { assignTaskToApplicant: true },
        });
        break;
      case "respondToTask":
        if (this.splitService.getSplitView()) this.splitService.clearSplit();
        this.routerService.navigate(["opportunities"], {
          skipLocationChange: true,
          queryParams: { respondToTask: true },
        });
        break;
    }
  }
}
